import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: Poppins, Open-Sans, Helvetica, Sans-Serif;
    
  }

`;
 
export default GlobalStyle;

//box-sizing: border-box;
//border: 1px solid black;

/*
 * * * * * {
    outline: 1px solid red;
   } 
*/
