import React, {useState} from "react";
import { Link, } from "react-router-dom";
// import { UnorderedList, ListItem, Flex, Box, useBreakpointValue } from "@chakra-ui/react";
import { Box, Flex  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
// import { SlideDownHeading, FadeInBoxRight } from './Common.jsx';
//import { FadeInBoxLeft } from './Common.jsx';

import { noop } from "../util/constants.js";


export function AgeBar (props) {

  return (
    <Box>
        Age Bar
    </Box>
  )
};


const stages = ["home", "rules", "groups", "league", "quarters", "semis", "finals", "results", "sponsors"];

const StageButton = ( {label, onStageSelect, selectedStage} ) => {

    return (
        <Link to={label} style={{all: 'unset'}}>
            <Box
                cursor='pointer'
                padding='5px'
                fontSize='1.2em'
                borderBottom={(selectedStage === label) ? 'solid 3px Red' : 'none'}
                onClick={() => {onStageSelect(label);}}>
                {label}
            </Box>
        </Link>
    );
  
  };

export default function StageBar (props) {

    const [selectedStage, setSelectedStage] = useState('home'); 

    return (
        <Flex
            wrap='nowrap'
            bg='#000000'
            color='#ffffff'
            padding='5px'
            whiteSpace='nowrap'
            paddingLeft='10px'
            margin='0'
            scrollBehavior='smooth'
            overflowX='auto'
        >
            <>
            {stages.map((item, index) => (
                <StageButton
                    key={index}
                    label={item}
                    onStageSelect={setSelectedStage}
                    selectedStage={selectedStage}
                />
            ))}
            </>
        </Flex>
  )
};



/* export const ListWrapper = styled.ul`
  list-style: none;
  padding-left: 10px;
  margin: 0;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
`;

export const ListStyled = styled.li`
  float: left;
  cursor: pointer;
  padding: 5px;
  font-size: 1.2em;
  text-transform: capitalize;
`;
 */