import React from 'react';
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, Button} from '@chakra-ui/react';
//import { Text, IconButton, Button, Stack, Collapse, Icon, Popover } from '@chakra-ui/react';
//import { PopoverTrigger, PopoverContent, useColorModeValue, useBreakpointValue, useDisclosure } from '@chakra-ui/react';    

//import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, MenuGroup  } from '@chakra-ui/react'

//import { Link } from '@chakra-ui/react';
// import { Link as ReactLink } from "react-router-dom";

import { HamburgerIcon } from '@chakra-ui/icons';
// import { CloseIcon } from '@chakra-ui/icons';
// import { ChevronDownIcon, ChevronRightIcon, } from '@chakra-ui/icons';
import { BsPersonLinesFill } from 'react-icons/bs';
//import { defaultUser } from "../util/constants.js";

// import { BUFF }  from "../util/constants.js";


/* <HamburgerIcon w={5} h={5} onClick={ () => {setIsOpen(true)} } /> */


export const ActionMenu = ( {userProps} ) => {

    const navigate = useNavigate()

    if (userProps.user !== 'admin') {
        return (
            <Menu bg='white' >
            <MenuButton as={Button} onClick={()=>{navigate('/login')}}>
                <BsPersonLinesFill w={8} />
            </MenuButton>
        </Menu>
        )
    }

    return (
        <Menu bg='white' >
            <MenuButton as={Button}>
                <HamburgerIcon w={8} />
            </MenuButton>
            <MenuList>
                <MenuItem onClick={()=>{console.log('NEXT')}}>Next</MenuItem>
                <MenuItem onClick={()=>{navigate('/back')}}>Back</MenuItem>
                <MenuDivider/>
                <MenuItem onClick={()=>{navigate('/report/team')}}>Team Report</MenuItem>
                <MenuItem onClick={()=>{navigate('/report/user')}}>User Report</MenuItem>
                <MenuDivider/>
                <MenuItem onClick={()=>{navigate('/manage/teams')}}>Manage Teams</MenuItem>
                <MenuDivider/>
                <MenuItem onClick={()=>{navigate('/user-guide')}}>User Guide</MenuItem>
                <MenuItem onClick={()=>{navigate('/logout')}}>Sign Out</MenuItem>
            </MenuList>
        </Menu>
    )

};