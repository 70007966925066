import React, {useState, useEffect} from "react";
import { Link as ReactLink } from "react-router-dom";
import { Container, Divider, Heading, Text, Stack, Flex, Button, VStack } from "@chakra-ui/react";
import { Box, Center, useColorModeValue, Image, useBreakpointValue, keyframes } from '@chakra-ui/react';


//import { ScaleFade } from "@chakra-ui/react";
import '../theme/style.css'

//import { useDeprecatedInvertedScale } from "framer-motion";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';

import {  CYAN, BUFF, isTouchScreen } from '../util/constants.js';

const myOpacity = isTouchScreen ? '1.0' : '0.2'


const FadeKeyFrames = keyframes({
    'from': {
        opacity: 0,
        },
    'to': {
        opacity: 1,
    }
});
  

const FadeInFromLeft = keyframes({
    'from': {
        opacity: 0,
        transform: 'translateX(-20px)',
        },
    'to': {
        opacity: 1,
        transform: 'translate(0px)',
    }
});


export const FadeInBoxLeft = ( props ) => {
    return (
       <Box overflow='hidden'>
           <Box
                width='100%'
                animation={`${FadeInFromLeft} .7s ease 600ms backwards`}
                {...props}
            >
                {props.children}
            </Box>
       </Box> 
    )
};

const FadeInFromRight = keyframes({
    'from': {
        opacity: 0,
        transform: 'translateX(20px)',
        },
    'to': {
        opacity: 1,
        transform: 'translate(0px)',
    }
});


export const FadeInBoxRight = ( props ) => {
    return (
       <Box overflow='hidden'>
           <Box
                width='100%'
                animation={`${FadeInFromRight} .8s ease 200ms backwards`}
                {...props}
            >
                {props.children}
            </Box>
       </Box> 
    )
};


const dropIn = keyframes({
    'from': {
        opacity: 0,
        transform: 'translateY(-100px)',
        },
    'to': {
        opacity: 1,
        transform: 'translate(0px)',
    }
});

//fontSize={useBreakpointValue({base: '1.6rem', sm: '1.6rem', md: '2.0rem'})}

export const SlideDownHeading = ( props ) => {
    return (
       <Box overflow='hidden'>
           <Heading
                as={'div'}
                m={2} p={2}
                fontSize={['1.0rem', '1.2rem', '1.4rem', '1.6rem']}
                color='white'
                animation={`${dropIn} 0.8s ease 600ms backwards`}
                width={'auto'}
                height={'auto'}
                {...props}
            >
                {props.children}
            </Heading>
       </Box> 
    )
}


export const Carousel = ( {data, mainText} ) => {

    // data = [
    //     {image: picturePath, mainText: "text", bodtTitle: "", bodyText: "text"},
    // ]

    const [ liveButton, setLiveButton ] = useState(0);

    const dataLength = data.length;


    useEffect(() => {
      const interval = setInterval( () => {
        setLiveButton((prevCounter) => (prevCounter + 1) % dataLength)
      }, 8000);
  
      return () => clearInterval(interval);
    }, []);


    const ButtonRow = () => {

        const Dot = ( {index} ) => {
            return (
                <Box
                    as='button'
                    height='20px'
                    width='20px'
                    backgroundColor={(index===liveButton) ? '#bbb' : 'white'}
                    border='solid 2px black'
                    borderRadius='50%'
                    _hover={{
                        cursor: 'pointer'
                    }}
                    onClick={ () => {setLiveButton(index)} }
                />
            )
        }

        return (
            <Stack justify='center' direction='row' >
                {
                data.map( (item, index) => {
                    return (
                        <Box key={index}>
                            <Dot index={index} />
                        </Box>
                    )
                })
                }
            </Stack>
        )
    }

    return (
        <Box py={[8, 12, 16, 20 ]}
            px={[2, 4, 8, 16 ]}
        >
            <Stack spacing='24px' direction='column'>
                <Box
                    fontSize={['1.1rem', '1.2rem', '1.3rem', '1.4rem']}
                    pt={4}
                    pb={8}
                >
                    {
                    mainText.map((para, index) => {
                        return (
                            <Text key={index} pt={1} align='center' >{para}</Text>
                        )
                    })
                    }
           </Box>
                <ReactLink to={`${data[liveButton].link}` ? `${data[liveButton].link}` : null} onClick={()=>{window.scroll(0,0)}} >
                <Stack justify='center' align='center' 
                    spacing={useBreakpointValue({base: '8px', sm: '16px', md: '32px' })} 
                    direction={useBreakpointValue({base: 'column', sm: 'row', md: 'row'})} 
                >
                    <Box className={`${(liveButton % 2) === 0 ? 'right-slide' : 'left-slide'}`} >
                        <Image width={['auto', '200px', '300px', '400px']} src={`/${data[liveButton].image}`} alt={`image_${liveButton}`} />
                    </Box>
                    <Box className={`${(liveButton % 2) === 0 ? 'left-slide' : 'right-slide'}`} >
                        <Text pb={4} fontSize='1.3rem'>
                            {data[liveButton].bodyTitle}
                        </Text>
                        <Text maxWidth='400px'>
                        {data[liveButton].bodyText}
                        </Text>
                    </Box>
                </Stack>
                </ReactLink>
                <ButtonRow/>
            </Stack>
        </Box>

    )
};

export const TwoPaneWithButton = ( { data } ) => {

    return (
        <Stack
            py={16} px={{base: 4, sm: 0, md: 0, lg: 0}} bg={'lightblue'} justify='center' align='center' spacing='60px' 
            direction={useBreakpointValue({base: 'column', sm: 'row', md: 'row', lg: 'row'})}
        >
        {
            data.map( (item, index) => {
                return (
                    <Stack maxWidth={['350px', '250px', '300px', '350px']} key ={index} direction='column' 
                    >
                        <Image src={`/${item.image}`} alt={`image_${index}`} />
                        <Text
                            py={4} fontSize='1.3rem'
                            minH={['auto', '100px', 'auto', 'auto']}
                        >
                            {item.bodyTitle}
                        </Text>
                        <Text
                            pb={8}
                            minH={['auto', '120px', 'auto', 'auto']}
                        >
                            {item.bodyText}
                        </Text>
                        <Button
                            as={ReactLink}
                            to={item.link} onClick={()=>{window.scroll(0,0)}}
                            colorScheme='cyan'
                        >                           
                            Learn more 
                        </Button>


                    </Stack>
                )
            })
        }
        </Stack>
    )
};


export const SinglePaneWithButton = ( { data, flipTextAndImage=false, useBackgroundColor='white', hasButton=true } ) => {

    var myTextAlign = 'right';
    if (flipTextAndImage) { myTextAlign = 'left'};

    return (
        <Stack
            px={['8px', '16px', '32px', '64px']}
            py={16} bg={useBackgroundColor}
            justify='center' align='center'
            spacing={['30px', '40px', '50px', '60px']} 
            direction={useBreakpointValue({base: 'column', sm: 'row', md: 'row', lg: 'row'})}
        >
            <Box maxW='350px' width={['auto', '250px', '300px', '350px']} display={flipTextAndImage ? 'block' : 'none'}>
                <Image  src={`/${data.image}`} alt='image' />
            </Box>

            <Stack direction='column'
                width={['auto', '60%', '60%', '60%']}
                maxW={['auto', '60%', '60%', '600px']}
            >
                <Text
                    align={['center', myTextAlign, myTextAlign, myTextAlign]} py={2} 
                    fontSize='1.3rem'
                    fontWeight='bold'
                >
                    {data.bodyTitle}
                </Text>
                    {
                    data.bodyText.map((para, index) => {
                        return (
                            <Text key={index} pt={1} align={['center', myTextAlign, myTextAlign, myTextAlign]} >{para}</Text>
                        )
                    })
                    }
                <Box 
                    align={useBreakpointValue({base: 'center', sm: myTextAlign, md: myTextAlign, lg: myTextAlign})}
                    display={hasButton ? 'block' : 'none'}>
                    <Button px={8} colorScheme='cyan'
                        as={ReactLink}
                        to={data.link}
                        onClick={()=>{window.scroll(0,0)}}
                    >
                            {data.buttonText}
                    </Button>
                </Box>

            </Stack>
            
            <Box maxW='320px' display={flipTextAndImage ? 'none' : 'block'}>
                <Image src={`/${data.image}`} alt='image' />      
            </Box>

        </Stack>
    )
};


export const SolidBlock = ( { bodyText, useBackgroundColor='cyan.600'} ) => {

    return (
        <Box animation={ `${FadeInFromRight} .8s ease 600ms backwards` } py={10} px={[4, 32]} bg={useBackgroundColor} align='center' color='white'
            fontSize={['1.2rem', '1.4rem', '1.6rem']}>
            <Text fontSize='2rem' fontStyle='italic' fontWeight='bold' align='left'>"</Text>
            <Box justify='center'>
                {bodyText}
            </Box>
            <Box fontSize='2rem' fontWeight='bold' fontStyle='italic' align='right' >"</Box>
        </Box>
    )
};


export const TextHighlightAndButton = ( { data, useBackgroundColor='cyan.600', hasButton=true } ) => {

    let openInNewWindow = false;
    if (data.link.slice(0,4) === 'http') {openInNewWindow = true}

    let myTitleColor = 'cyan.800'
    if (useBackgroundColor !== 'white') myTitleColor = 'white'

    return (
        <VStack
            animation={ `${FadeInFromRight} .5s ease 500ms backwards` }
            py={10} px={[4, 8, 16, 32]} 
            bg={useBackgroundColor}
            align='center'
        >
            <Text fontSize={['.9rem', '1rem', '1.1rem', '1.2rem']} textTransform='uppercase' fontWeight='bold' align='center'>
                {data.title}
            </Text>
            <Text fontSize={['1.2rem', '1.4rem', '1.8rem', '2rem']} fontWeight='bold' color={myTitleColor} align='center'>
                {data.highlight}
            </Text>
            {
                data.bodyText.map((item, index)=>{
                    return (
                        <Text fontSize={['.9rem', '1rem', '1.1rem', '1.2rem']} key={index} align='center'>
                            {item}
                        </Text>
                    )
                })
            }

            <Box 
                align='center'
                display={hasButton ? 'block' : 'none'}
            >
                <Button
                    px={8} colorScheme='cyan'
                    as={ReactLink}
                    to={data.link} target={openInNewWindow ? '_blank': null } rel={openInNewWindow ? 'noreferrer' : null}
                    onClick={()=>{window.scroll(0,0)}}
                >
                    {data.buttonText}
                </Button>
            </Box>
            
        </VStack>
    )
};


export const ThreeColumnTextBlock = ( { data, mainTitle, useBackgroundColor='blue.100'} ) => {

    const threeColStyle = {
        columnCount: 3,
        columnGap: '60px',
        columnRule: '1px solid black',
        backgroundColor: '#EBF8FF', //blue.50
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingBottom: '50px',
        paddingTop: '10px',
        color: 'black',
    };

    const twoColStyle = {
        columnCount: 2,
        columnGap: '40px',
        columnRule: '1px solid black',
        backgroundColor: '#EBF8FF', //blue.50
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingBottom: '40px',
        paddingTop: '10px',
        color: 'black',
    };

    const oneColStyle = {
        columnCount: 1,
        columnGap: '30px',
        columnRule: '1px solid black',
        backgroundColor: '#EBF8FF', //blue.50
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingBottom: '30px',
        paddingTop: '10px',
        color: 'black',
    };


    return (
        <>
        <Text bg='white' paddingTop='30px' paddingBottom='8px' fontSize='1.2rem' textTransform='uppercase' fontWeight='bold' align='center' color='black' >
            {mainTitle}
        </Text>  
        <div
            style={useBreakpointValue({base: oneColStyle, sm: oneColStyle, md: twoColStyle, lg: threeColStyle})}
        >
            {
                data.map((item, firstIndex)=>{
                    return (
                        <Box key={firstIndex} >
                            <Text color='black' paddingTop='16px' fontSize='1.2rem' >{item.title}</Text>
                            {
                            item.bodyText.map((para, secondIndex) => {
                                return (
                                    <Text key={secondIndex} paddingTop='8px' fontSize='0.9rem' >{para}</Text>
                                )
                            })
                            }
                            
                        </Box>
                    )
                })
            }
        </div>
        </>
    )
};


export const ThreeIcon = ( { data, mainTitle, useBackgroundColor='cyan.600', invertImage=true} ) => {

    const MyIcon = ( { image}  ) => {

        return (
            <Center 
                width={['140px', '100px', '120px', '140px']}
                height={['140px', '100px', '120px', '140px']}
                backgroundColor={useBackgroundColor}
                borderRadius='50%'
                color='white'
                align='center'
                justify='center'
            >
                <Image
                    filter={invertImage ? 'invert(100%)' : 'none'}
                    width='75%' src={`/${image}`} alt={image}
                />
            </Center>
        )

    }

    return (
        <>
        <Text px={[2,8]} fontSize='1.2rem' textTransform='uppercase' fontWeight='bold' align='center' color='cyan.600' >
            {mainTitle}
        </Text>  
        <Stack
            width='100%' spacing={[2, 4, 8, 8]} 
            direction={['column', 'row', 'row', 'row']}
            py={10} px={[2, 4, 6, 10]}
            align={useBreakpointValue({base: 'center', sm: 'top', md: 'top', lg: 'top'})}
            justify='center'
            color='black'
        >
            {
                data.map((item, index)=>{
                    return (
                        <Box key={index}
                            width={['280px', '180px', '200px', '260px']}
                        >
                            <Center>
                                <MyIcon image={item.image}/>
                            </Center>
                            <Text fontSize='1.2rem' pt={6} align='center'>{item.title}</Text>
                            {
                                item.bodyText.map((para, secondIndex) => {
                                    return (
                                        <Text key={secondIndex} align='center' pt={2} fontSize='0.9rem' >{para}</Text>
                                    )
                                })
                            }
                            
                        </Box>
                    )
                })

            }
        </Stack>
        </>
    )
};


export const Cards = ( {data} ) => {

    return (
        <Flex
            py={12}
            flexWrap='wrap'
            justify='center'
            minHeight='10xl'
            columnGap='30px'
        >
        {
            data.map( (item, index) => {
                return (
                    <SimpleCard 
                        key={index}
                        item={item}
                    />
                )
            })

        }

    </Flex>
    )
}


export const Card = ({children, title}, props) => {

    return (
        <Container
            border='1px'
            borderRadius='lg'
            boxShadow='4px 4px 8px #317369'
            m='8px'
            mt='20px'
            p='8px'
            fontSize='xs'
            _hover={{
                boxShadow: '0 0',
                border: '3px solid #317369'
              }}
            {...props}
        >
            <Stack>
                <Heading
                  fontSize='md'
                  color='#317369'
                  align='center'
                >
                    {title}
                </Heading>
                <Divider/>
                {children}
            </Stack>
        </Container>
    )
};




export const PageTitle = ({children}, props) => {

    return (
            <Container
                fontWeight='bold'
                fontSize={['1.8rem', '2.0rem', '2.2rem', '2.2rem']}
                color='cyan.800'
                align='center'
                verticalAlign='middle'
                p={8}
                animation={`${dropIn} 0.8s ease 100ms backwards`}
                {...props}

            >
                {children}
            </Container>
    )
};


export const LogoBanner = ({ logoData }, props) => {

    return (
        <>
        <Stack
            width='100%' spacing={[4, 16, 16, 32]} 
            direction={['column', 'row', 'row', 'row']}
            py={10} px={[2, 4, 6, 10]}
            align={useBreakpointValue({base: 'center', sm: 'top', md: 'top', lg: 'top'})}
            justify='center'
            color='black'
        >
            {
                logoData.map( (item, index) => {
                    return (
                        <Box py={[4, 4, 4, 4 ]} key={index} width={['140px', '80px', '90px', '100px']}>
                            <a href={item.link}  target='_blank' rel='noreferrer'>
                                <Image src={item.image} opacity={myOpacity} _hover={{opacity:'1', cursor: 'pointer'}} />
                            </a>
                        </Box>
                    )
                })

            }
        </Stack>
        </>
    )
};
  
    
export const SimpleCard = ( { item } ) => {

    return (
    <Center py={12}>
    <ReactLink to={item.link} onClick={()=>{window.scroll(0,0)}}>
    <Box
        role={'group'}
        p={6}
        maxW={'330px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'lg'}
        pos={'relative'}
        zIndex={1}
    >
        <Box
            rounded={'lg'}
            mt={-12}
            pos={'relative'}
            height={'230px'}
            _after={{
                transition: 'all .3s ease',
                content: '""',
                w: 'full',
                h: 'full',
                pos: 'absolute',
                top: 5,
                left: 0,
                backgroundImage: item.image,
                filter: 'blur(15px)',
                zIndex: -1,
            }}
            _groupHover={{
                _after: {
                filter: 'blur(20px)',
                },
            }}>
                <Image
                    rounded={'lg'}
                    height={230}
                    width={282}
                    objectFit={'cover'}
                    src={item.image}
                />
        </Box>
        <Stack pt={10} align={'center'}>
            <Text color={'gray.500'} fontSize={'sm'} textTransform={'uppercase'}>
                {item.bodyTitle}
            </Text>
            
            <Heading fontSize={'1xl'} fontFamily={'body'} fontWeight={500}>
                {item.bodyText}
            </Heading>

            <Stack direction={'row'} align={'center'}>
                
                    <Text fontWeight={800} fontSize={'lg'}>
                    {item.buttonText}
                    </Text>
                    <Text textDecoration={'line-through'} color={'gray.600'}>
                    </Text>
            
            </Stack>
        </Stack>
    </Box>
    </ReactLink>
    </Center>
)
};