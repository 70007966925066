import React from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel,
   Box, useBreakpointValue } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
// import { SlideDownHeading, FadeInBoxRight } from './Common.jsx';
//import { FadeInBoxLeft } from './Common.jsx';


export default function TeamReport (props) {

  return (
    <Box p={12} fontSize='2xl'>
        Team Report page
    </Box>
  )
};

export function UserReport (props) {

  return (
    <Box p={12} fontSize='2xl'>
        User Report page
    </Box>
  )
};