import React, { useEffect } from "react";
import {
  FormControl, FormLabel, Input, FormHelperText, FormErrorMessage,
  Button, Heading, Box, useBreakpointValue
} from "@chakra-ui/react";

import { useToastHook } from "./Toast.jsx";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';

//import { restRequest } from "../util/utils.js";
import { POST, BASE_URL } from "../util/constants.js";
import { useNavigate } from "react-router-dom";



export function LogIn({ userProps = null }, props) {

  const [userName, setUserName] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [isUserNameError, setIsUserNameError] = React.useState(false);
  const [isPasswordError, setIsPasswordError] = React.useState(false)

  const [state, newToast] = useToastHook();



  const handlePasswordInputChange = (e) => {
    setPassword(e.target.value)
    setIsPasswordError(false)
      ;
  }

  const handleUserNameInputChange = (e) => {
    setUserName(e.target.value)
    setIsUserNameError(false)
      ;
  }

  const handleSubmit = () => {

    if (userName === '') {
      setIsUserNameError(true)
      return
    }
    setIsUserNameError(false)

    if (password === '') {
      setIsPasswordError(true)
      return
    }
    setIsPasswordError(false)

    const fetchData = {
      method: POST,
      body: JSON.stringify({ username: userName, password: password }),
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json'

      })
    }

    userProps.signIn(userName)

    /*
        var wasSuccessful = true;
    
        fetch(`${BASE_URL}logon/`, fetchData)
          
          .then( response=>{
            wasSuccessful = response.ok;
            return ( response.json() )
          })
    
          .then( data=>{
            if (wasSuccessful) {
              newToast({ message: `${data.username} sign-in succcessful`, status: "success" });
              setUserName('')
              setPassword('')
              const tempUser = {
                username: data.username,
                role: data.role
              }
              userProps.signIn(tempUser);
              window.sessionStorage.setItem('accessToken', data.accessToken);
              navigate('/emails')
            } else {
              newToast({ message: `${data.message}`, status: "error" });
            }
          })
          
          .catch((error) => {
            newToast({ message: `Oops, something went wrong! ${error}`, status: "error" });
          })
     */

  };

  return (
    <Box pt={'60px'} m={4} pl={[2, 8]} maxW={useBreakpointValue({ base: 'xl', md: '3xl' })}>
      <Heading pb={'60px'} color='gray.600' size='md'>Sign-in</Heading>
      <FormControl isInvalid={isUserNameError || isPasswordError} {...props}>
        <FormLabel>Username</FormLabel>
        <Input id='username' type='text' value={userName} onChange={handleUserNameInputChange} />
        {!isUserNameError ? (
          <FormHelperText pb={8} >
            Enter your username.
          </FormHelperText>
        ) : (
          <FormErrorMessage pb={8} >Cannot be blank.</FormErrorMessage>
        )}
        <FormLabel>Password</FormLabel>
        <Input id='password' type='password' value={password} onChange={handlePasswordInputChange} />
        {!isPasswordError ? (
          <FormHelperText>
            Enter your password.
          </FormHelperText>
        ) : (
          <FormErrorMessage>Cannot be Blank.</FormErrorMessage>
        )}
        <Button
          mt={4}
          colorScheme='gray'
          type='submit'
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  )
};

export function LogOut({ userProps }) {

  const navigate = useNavigate()

  return (
    <Box pt={'60px'} m={4} pl={[2, 8]} maxW={useBreakpointValue({ base: 'xl', md: '3xl' })}>
      <Heading pb={'60px'} color='gray.600' size='md'>Sign Out</Heading>
      <Box>
        <FormLabel>Press Sign Out to confirm, or Cancel</FormLabel>
        <Button
          mt={4}
          colorScheme='gray'
          type='submit'
          onClick={() => { userProps.signOut() }}
        >
          Sign Out
        </Button>
        <Button
          mt={4}
          ml={12}
          colorScheme='gray'
          type='cancel'
          onClick={() => { navigate(-1) }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
};