import React, {useState} from 'react';
import GlobalStyle from './theme/globalStyles.js';
import { ChakraProvider, Image, theme, Box, extendTheme, keyframes,
  Text, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
//import { Link, VStack, Grid } from '@chakra-ui/react';
import './theme/style.css'

import { Routes, Route, Outlet, useNavigate, Link  } from "react-router-dom";
//import { ColorModeSwitcher } from './ColorModeSwitcher';

import { useToastHook } from "./components/Toast.jsx";


// import flyer from './img/CharityBall.png';
// import wideFlyer from './img/LandscapePoster.png';
//import { Logo } from './Logo';

// import { defaultUser } from "./util/constants.js";
import { POST, BASE_URL } from "./util/constants.js";
// import { FadeInBoxLeft, FadeInBoxRight } from './components/Common.jsx';

import StageBar from './components/NavBars.jsx';
import Groups from './components/Groups.jsx';
import Footer from './components/Footer.jsx';
import Home, {Terms, Privacy, PageNotFound, Blank} from './components/Home.jsx';
import TeamReport, {UserReport} from './components/Report.jsx';
import {ActionMenu} from './components/Menu.jsx';
import {LogIn, LogOut} from './components/LogInLogOut.jsx';
import Netball from "./img/netball192.png";


function App() {
    
    const navigate = useNavigate()

    const signOut = () => {

        setUserProps({
            user: 'guest',
            signIn: signIn,
            signOut: signOut,
        })

        navigate('/home')
    };
  
    const signIn = (userName) => {

        setUserProps({
            user: userName,
            signIn: signIn,
            signOut: signOut,
        })
        
        navigate('/home')
    };

    const defaultUserProps = {
        user: 'guest',
        signIn: signIn,
        signOut: signOut,
    };

    const [userProps, setUserProps] = useState(defaultUserProps)

//   const [state, newToast] = useToastHook();
//   const navigate = useNavigate()
  

  /* const handleSignout = () => {

     
    const fetchData = {
      method: POST,
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json'
  
      })
    }
  
    fetch(`${BASE_URL}logout/`, fetchData)
      .then( response=>{
        return ( response.json() )
      })
      .then( data=>{
        newToast({ message: `${currentUser.username} signed out`, status: "success" });
        setCurrentUser(defaultUser);
        window.sessionStorage.removeItem('accessToken');
        navigate('/home')
      })
      .catch((error) => {
        console.log("sign-out", error);
      })
  
  }; */

  const breakpoints = {
    base: "0px",  //0px
    sm: "500px",  //320px
    md: "700px",  //768px
    lg: "900px",  //992px
    xl: "1200px", //1280px
    "2xl": "1536px",  //1536px
  }

  const customTheme = extendTheme({breakpoints});

  return (
    <ChakraProvider theme={customTheme} >
      <GlobalStyle />
      <Routes>
        <Route path="/" element={ <Layout userProps={userProps}/> } >
          <Route path='' element={<Home />} />
          <Route path='home' element={<Home />} />
          <Route path='rules' element={<Blank text='Rules'/>} />
          <Route path='groups' element={<Groups />} />
          <Route path='league' element={<Blank text='League Matches' />} />
          <Route path='quarters' element={<Blank text='Quarter Finals' />} />
          <Route path='semis' element={<Blank text='Semi Finals' />} />
          <Route path='finals' element={<Blank text='Finals' />} />
          <Route path='results' element={<Blank text='Results' />} />
          <Route path='sponsors' element={<Blank text='Sponsors' />} />
          <Route path='back' element={<Blank text='Back' />} />
          <Route path='report/team' element={<TeamReport />} />
          <Route path='report/user' element={<UserReport />} />
          <Route path='manage/teams' element={<Blank text='Manage Teams' />} />
          <Route path='user-guide' element={<Blank text='User Guide' />} />
          <Route path='login' element={<LogIn userProps={userProps} />} />
          <Route path='logout' element={<LogOut userProps={userProps} />} />
          <Route path='terms' element={<Terms />} />
          <Route path='privacy' element={<Privacy />} />
          <Route path='*' element={<PageNotFound/>} />
        </Route>
      </Routes>
    </ChakraProvider>
  );
};


const Layout = ( {userProps} ) => {

    return (
        <Grid
            minHeight={'100vh'}
            gridTemplateRows="auto auto auto 1fr auto"
            gridTemplateColumns="auto 1fr 80px"
            gridTemplateAreas={`
                "logo pageTitle menu"
                "ageBar ageBar ageBar"
                "navBar navBar navBar"
                "main main main"
                "footer footer footer"`
            }
        >
            <GridItem area={'logo'}>
                <img width='120px' src={Netball}/>
            </GridItem>

            <GridItem fontSize='2xl' area={'pageTitle'} justifySelf='center' alignSelf='center' >
                Netball Tournament Website
            </GridItem>

            <GridItem area={'menu'}  alignSelf='center' >
                <ActionMenu userProps={userProps} />
            </GridItem>

            <GridItem area={'navBar'}>
                <StageBar/>
            </GridItem>

            <GridItem area={'main'} justifySelf={useBreakpointValue({ base: 'start', md: 'center' })} >
                <Outlet  />
            </GridItem>

            <GridItem area={'footer'} width='100%' >
                <Footer/>
            </GridItem>
        </Grid>
    )
};



export default App;