import React from "react";
import { Text, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel,
   Box, useBreakpointValue } from "@chakra-ui/react";
import { PageTitle, ThreeColumnTextBlock, FadeInBoxRight } from './Common.jsx';


// import {privacyData}  from '../privacy.js';
// import {termsData}  from '../terms.js';
import {loremIpsumData}  from '../loremIpsum.js';
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
// import { SlideDownHeading, FadeInBoxRight } from './Common.jsx';
//import { FadeInBoxLeft } from './Common.jsx';


export default function Home (props) {

  return (
    <Box p={12} fontSize='2xl'>
        Home page
    </Box>
  )
};


export const Terms = (props) => {

    return (
      <>
      <PageTitle>Terms And Conditions</PageTitle>
      <FadeInBoxRight>
          <ThreeColumnTextBlock data={loremIpsumData} mainTitle={null} />
      </FadeInBoxRight>
      </>
    )
}; 

export const Privacy = (props) => {

    return (
      <>
      <PageTitle>Privacy</PageTitle>
      <FadeInBoxRight>
          <ThreeColumnTextBlock data={loremIpsumData} mainTitle={null} />
      </FadeInBoxRight>
      </>
    )
};
  


export const PageNotFound = (props) => {
  return (
    <Text {...props}>
      Page not found.
    </Text>
  )
};

export const Blank = ({text}, props) => {
  return (
    <Text p={12} fontSize='2xl' {...props}>
      {text}
    </Text>
  )
};