export const loremIpsumData = [
    {
      title: 'section 1',
      bodyText: [
        "Pellentesque interdum mi et urna imperdiet, ac maximus elit bibendum. Maecenas lorem eros, pretium vitae dui vitae, auctor accumsan ligula",
        "Suspendisse cursus sem et eleifend eleifend.",
        "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur viverra magna vitae mi rhoncus, et gravida enim pellentesque."
      ]
    },
    {
      title: 'section 2',
      bodyText: [
          "Aenean vitae pharetra magna, ut aliquet sapien. ",
          "Nulla imperdiet gravida tincidunt. Aenean sit amet lorem id tellus porttitor iaculis.",
          "Morbi vehicula justo id neque auctor faucibus. Praesent volutpat, nisl sed eleifend eleifend, orci tellus rhoncus velit, mollis imperdiet magna tellus nec quam.",
          "Morbi suscipit, mi nec feugiat rhoncus, magna mi venenatis dui, non rutrum nulla tortor eu justo.",
          
      ]
    },
    {
      title: 'section 3',
      bodyText: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          "Etiam viverra nibh id enim dignissim, eget eleifend elit pellentesque. Nunc non mauris non risus gravida vestibulum.",
          "Cras finibus urna id nulla tincidunt fermentum nec at eros. Maecenas eget nisl lacus.",
          "Nulla lacinia neque sit amet sem feugiat ornare. Sed tincidunt mauris vel leo rhoncus pellentesque.",
      ]
    },
    {
      title: 'section 4',
      bodyText: [
            "Sed tempus, tellus eu euismod hendrerit, mauris est scelerisque libero, at consectetur diam arcu non nunc. Praesent finibus sem vel lacinia tincidunt.",
            "Etiam at nisi at nulla faucibus molestie in a nisi. Nam et porta dolor.",
            "Aliquam fermentum nec elit sed feugiat.",
      ]
    },
    {
      title: 'section 5',
      bodyText: [
          "Duis non sagittis augue, quis vestibulum libero. Ut efficitur pulvinar enim vel imperdiet. Vivamus a leo dolor. Morbi volutpat dolor ac lorem laoreet, nec vehicula diam pulvinar.",
          "Ut arcu orci, elementum ut ipsum quis, tristique gravida lectus. Cras suscipit urna ac massa sagittis, vel efficitur nibh facilisis.",
          "Praesent in nibh massa. Pellentesque blandit metus vel commodo vehicula. Sed mattis, leo eget sollicitudin sagittis, arcu mi vulputate enim, ac fringilla nulla dolor eget dolor.",
      ]
    },
  ]