export const noop = () => {};

export const isTouchScreen = (window.matchMedia("(any-pointer: coarse)").matches) ? true : false;

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
export const BASE_URL = '/api/';

export const BUFF = `#fff8dc`;

export const CYAN = {
    50: `#EDFDFD`,
    100: `#C4F1F9`,
    200: `#9DECF9`,
    300: `#76E4F7`,
    400: `#0BC5EA`,
    500: `#00B5D8`,
    600: `#00A3C4`,
    700: `#0987A0`,
    800: `#086F83`,
    900: `#065666`,
}
